/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dropdown } from 'primereact/dropdown';
import './ManageCampaignFormComponent.scss';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Checkbox } from 'primereact/checkbox';
import { useSelector } from 'react-redux';
import yaml from 'js-yaml';
import domains from '@config/domains';
import { Tooltip } from 'primereact/tooltip';
import { InputSwitch } from 'primereact/inputswitch';
import TestMailModalComponent from './TestMailModalComponent/TestMailModalComponent';
import { getTemplates } from '../../../apis/templates.ts';
import { getLandingPages } from '../../../apis/landingPages.ts';
import { getGroups } from '../../../apis/usersAndGroups.ts';
import { createCampaign } from '../../../apis/campaigns.ts';
import { useToast } from '../../../context/ToastContext';
import CampaignResumeComponent from './CampaignResumeComponent/CampaignResumeComponent';
import CustomSpinnerComponent from '../../CustomSpinnerComponent/CustomSpinnerComponent';
import CustomConfirmationDialog from './CustomConfirmationDialog';
import getUserInfoState from '../../../redux/reducers/selectors/userInfo';
import { ownerInfo } from '../../utils';
import { difficultyOptions } from '../utils';

const protocols = [
  { name: 'http', value: 'http' },
  { name: 'https', value: 'https' },
];

// Check if the URL of the stored campaign was a manual introduced one
function checkIfManualUrl(url, domainOptions) {
  const urlDomain = parseUrlString(url)?.domain;
  return (!(domainOptions?.some((domain) => domain.name === urlDomain)));
}

const buildURL = (url, domainOptions, manual) => {
  if (manual) return url;
  if (checkIfManualUrl(url.domain, domainOptions)) {
    return '';
  }
  const cleanUrl = Object.fromEntries(
    Object.entries(url).filter(([_, v]) => v !== undefined || v !== null),
  );
  if (Object.entries(cleanUrl).every(([_, value]) => value === '' || !value)) {
    return null;
  }
  return `${cleanUrl.protocol ? cleanUrl.protocol : 'https'}://${cleanUrl.subdomain ? `${cleanUrl.subdomain}.` : ''}${cleanUrl.domain ? cleanUrl.domain : ''}${cleanUrl.path ? `/${cleanUrl.path}` : ''}`;
};

const parseUrlString = (str) => {
  if (str) {
    let decodedString = str.replace(/\\u(\w{4})/g, (match, grp) => String.fromCharCode(parseInt(grp, 16)));

    if (!/^https?:\/\//i.test(decodedString)) {
      decodedString = `https://${decodedString}`;
    }

    const regex = /^(https?):\/\/(?:([a-zA-Z0-9-]+)\.)?([a-zA-Z0-9-]+)\.([a-z]{2,})(\/[a-zA-Z0-9-/_]*)?$/i;
    const match = decodedString.match(regex);

    if (match) {
      const protocol = match[1];
      const subdomain = match[2] ? match[2].trim() : null;
      const domain = `${match[3]}.${match[4]}`;
      let path = match[5] ? match[5].trim() : '';
      path = path.startsWith('/') ? path.slice(1) : path;

      return {
        protocol,
        subdomain,
        domain,
        path,
      };
    }
    return {
      domain: str,
    };
  }
  return null;
};

function DropdownLoader() {
  return <span><ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" /></span>;
}

async function handleCreateCampaign(
  data,
  setVisible,
  setVisibleConfirmation,
  toast,
  navigate,
  setIsSubmitting,
) {
  const timeoutId = setTimeout(() => {
    toast.current.show({
      severity: 'info',
      summary: 'Processing',
      detail: 'Creating campaign. You will redirected to the campaign page once it is created.',
      life: 3000,
    });
    setVisible(false);
    setVisibleConfirmation(false);
  }, 1000);

  const timeoutPromise = new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout: Response took too long')), 30000));

  try {
    const sanitizedSendData = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).filter(([_, v]) => v !== undefined),
    );
    const {
      sendingProfile, learningPage, credentialsPage, template, ...sendData
    } = sanitizedSendData;

    const apiPromise = createCampaign({
      ...sendData,
      ...(sendingProfile.interface_type === 'SMS Gateway'
        ? { sms_gateway: sendingProfile?.id }
        : { smtp: sendingProfile?.id }),
      learning_page: learningPage.id,
      credentials_page: credentialsPage?.id,
      template: template.id,
    });

    const response = await Promise.race([apiPromise, timeoutPromise]);

    clearTimeout(timeoutId);

    if (response.id) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Campaign was created successfully.',
        life: 3000,
      });
      navigate(`/campaign/${response.id}`);
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: response?.response?.data?.message
          ? `There was a problem creating the campaign: ${response.response.data.message}`
          : 'An unexpected error occurred creating the campaign',
        life: 3000,
      });
    }
  } catch (error) {
    clearTimeout(timeoutId);

    if (error.message === 'Timeout: Response took too long') {
      toast.current.show({
        severity: 'warn',
        summary: 'Timeout',
        detail: 'Campaign creation is taking too long. Please, wait a moment and refresh the page to see it.',
        life: 5000,
      });
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: `Unexpected error: ${error.message}`,
        life: 3000,
      });
    }
  }
  setIsSubmitting(false);
}

function ConfirmationDialog({
  visibleConfirmation,
  setVisibleConfirmation,
  sanitizedData,
  setVisible,
  toast,
  navigate,
  setIsSubmitting,
  isSubmitting,
}) {
  const [visibleConfirmationCustomDialog, setVisibleConfirmationCustomDialog] = useState(false);
  const handleConfirm = () => {
    setIsSubmitting(true);
    handleCreateCampaign(
      sanitizedData,
      setVisible,
      setVisibleConfirmation,
      toast,
      navigate,
      setIsSubmitting,
    );
  };

  return (
    <Dialog header="Campaign Resume" onHide={() => setVisibleConfirmation(false)} visible={visibleConfirmation} style={{ zIndex: 10000, width: '70%' }}>
      <CustomConfirmationDialog
        visible={visibleConfirmationCustomDialog}
        onHide={() => setVisibleConfirmationCustomDialog(false)}
        onConfirm={handleConfirm}
        message="Are you sure you want to send this campaign?"
        header="Confirmation"
      />
      <div className="confirmation-content">
        <CampaignResumeComponent campaignInfo={sanitizedData} />
      </div>
      <div className="confirmation-footer">
        <Button label="Edit" severity="secondary" icon="pi pi-pencil" onClick={() => setVisibleConfirmation(false)} className="left-button" />
        <Button label={isSubmitting ? 'Creating...' : 'Create'} disabled={isSubmitting} icon={isSubmitting ? 'pi pi-spin pi-spinner' : 'pi pi-envelope'} severity="primary" onClick={() => setVisibleConfirmationCustomDialog(true)} className="right-button" />
      </div>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  visibleConfirmation: PropTypes.bool.isRequired,
  setVisibleConfirmation: PropTypes.func.isRequired,
  sanitizedData: PropTypes.object.isRequired,
  setVisible: PropTypes.func.isRequired,
  toast: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  setIsSubmitting: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

function ManageCampaignFormComponent({
  campaign, sendingProfiles, setVisible, mode,
}) {
  const [templatesOptions, setTemplatesOptions] = useState(null);
  const [learningPagesOptions, setLearningPagesOptions] = useState(null);
  const [credentialsPagesOptions, setCredentialsPagesOptions] = useState(null);
  const [groupsOptions, setGroupsOptions] = useState(null);
  const [selectedTestType, setSelectedTestType] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedLearningPage, setSelectedLearningPage] = useState(null);
  const [selectedCredentialsPage, setSelectedCredentialsPage] = useState(null);
  const [selectedDifficulty, setSelectedDifficulty] = useState(null);
  const [selectedSendingProfile, setSelectedSendingProfile] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState(null);
  const [launchDate, setLaunchDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [visibleTestMail, setVisibleTestMail] = useState(false);
  const [sendingProfilesOptions, setSendingProfilesOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [visibleConfirmation, setVisibleConfirmation] = useState(false);
  const [sanitizedData, setSanitizedData] = useState();
  const toast = useToast();
  const navigate = useNavigate();
  const { id } = useSelector(getUserInfoState);
  const [url, setUrl] = useState({
    protocol: null, subdomain: null, domain: null, path: '',
  });
  const [domainOptions, setDomainOptions] = useState([]);
  const [manualUrlToggle, setManualUrlToggle] = useState(false);
  const [manualUrl, setManualUrl] = useState('');

  const schema = yup.object().shape({
    name: yup.string().min(2, 'Campaign name must be at least 2 characters').max(255, 'Campaign name must be at most 255 characters').required('Campaign name is required'),
    url: yup.object({
      subdomain: yup.string().nullable(),
      domain: yup.string().nullable(),
      path: yup.string().nullable(),
    })
      .required('URL is required')
      .test('url-required', 'URL is required', (value) => {
        const {
          domain,
        } = value || {};
        return !!domain;
      })
      .test('one-subdomain-level', 'Only one subdomain level is allowed', (value) => {
        const { subdomain } = value || {};
        if (subdomain === '' || !subdomain) {
          return true;
        }
        return !subdomain.includes('.');
      })
      .test('letters-numbers-hyphens', 'Subdomain can only contain letters, numbers, and hyphens', (value) => {
        const { subdomain } = value || {};
        if (subdomain === '' || !subdomain) {
          return true;
        }
        return /^[a-zA-Z0-9-]+$/.test(subdomain || '');
      })
      .test('path-letters-numbers-hyphens', 'Path can only contain letters, numbers, and hyphens', (value) => {
        const { path } = value || {};
        if (path === '' || !path) {
          return true;
        }
        return /^[a-zA-Z0-9-/]+$/.test(path || '');
      }),
    template: yup.object().test('template-required', 'Template is required', (value) => value.name),
    learningPage: yup.object().test('learning-page-required', 'Learning page is required', (value) => value.name),
    difficulty: yup.object().test('difficulty-required', 'Difficulty is required', (value) => value.name),
    sendingProfile: yup.object().shape({
      name: yup.string().required('Sending profile is required'),
    }).required('Sending profile is required'),
    groups: yup.array().min(1, 'At least one group is required').required('At least one group is required'),
  });

  const defaultValues = {
    name: '',
    url: {
      protocol: null, subdomain: null, domain: null, path: '',
    },
    test_type: true,
    template: null,
    learningPage: null,
    credentialsPage: null,
    launch_date: null,
    send_by_date: null,
    difficulty: null,
    sendingProfile: null,
    groups: null,
  };

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    reset,
    resetField,
    setValue,
    getValues,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    if (campaign) {
      let sendingProfile;
      if (campaign.sms_gateway !== 0) {
        sendingProfile = sendingProfiles.filter((s) => s.id === campaign.sms_gateway);
      } else {
        sendingProfile = sendingProfiles.filter((s) => s.id === campaign.smtp);
      }
      setSelectedTestType(true);
      const template = templatesOptions?.filter((p) => p.id === campaign.template);
      setSelectedTemplate(template?.[0] || null);
      const learningPage = learningPagesOptions?.filter((p) => p.id === campaign.learning_page);
      setSelectedLearningPage(learningPage?.[0] || null);
      const credentialsPage = credentialsPagesOptions?.filter(
        (p) => p.id === campaign.credentials_page,
      );
      setSelectedCredentialsPage(credentialsPage?.[0] || null);
      setSelectedDifficulty(campaign?.difficulty || null);
      setSelectedSendingProfile(sendingProfile?.[0] || null);
      const cleanUrl = parseUrlString(campaign.url);
      setUrl(cleanUrl || url);
      reset({
        name: `Copy of ${campaign.name}`,
        test_type: true,
        template: { name: template?.[0]?.name || null },
        learningPage: { name: learningPage?.[0]?.name || null },
        credentialsPage: { name: credentialsPage?.[0]?.name || null },
        url: cleanUrl || url,
        difficulty: { name: campaign?.difficulty || null },
        sendingProfile: { name: sendingProfile?.[0]?.name || null },
      });
      if (domainOptions.length > 0 && checkIfManualUrl(cleanUrl?.domain, domainOptions)) {
        setManualUrlToggle(true);
        setManualUrl(campaign.url);
      }
      trigger();
    }
  }, [campaign, learningPagesOptions, credentialsPagesOptions, templatesOptions, domainOptions]);

  useEffect(() => {
    async function fetchData() {
      const templates = await getTemplates();
      if (!Array.isArray(templates)) {
        toast.current.show({
          severity: 'error', summary: 'Error', detail: templates?.response?.data?.message ? templates?.response?.data?.message : 'An unexpected error occurred getting the templates', life: 3000,
        });
        setTemplatesOptions([]);
      } else {
        setTemplatesOptions(templates);
      }
      const pages = await getLandingPages();
      if (!Array.isArray(pages)) {
        toast.current.show({
          severity: 'error', summary: 'Error', detail: pages?.response?.data?.message ? pages?.response?.data?.message : 'An unexpected error occurred getting the landing pages', life: 3000,
        });
        setLearningPagesOptions([]);
        setCredentialsPagesOptions([]);
      } else {
        setLearningPagesOptions(pages.filter((p) => p.type === 'learning'));
        setCredentialsPagesOptions(pages.filter((p) => p.type === 'credentials'));
      }
      const groups = await getGroups();
      if (!Array.isArray(groups.groups)) {
        toast.current.show({
          severity: 'error', summary: 'Error', detail: groups?.response?.data?.message ? groups?.response?.data?.message : 'An unexpected error occurred getting the groups', life: 3000,
        });
        setGroupsOptions([]);
      } else {
        setGroupsOptions(groups.groups.filter((g) => !g.processing));
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchYamlData = async () => {
      try {
        fetch(domains)
          .then((r) => r.text())
          .then((text) => {
            const domainsList = yaml.loadAll(text).flat().filter((item) => item != null)
              .map((item) => ({
                name: item.id,
                value: item.id,
              }));
            setDomainOptions(domainsList);
          });
      } catch (error) {
        console.error('Error loading or parsing YAML file:', error);
      }
    };
    fetchYamlData();
  }, []);

  useEffect(() => {
    if (selectedTemplate && sendingProfiles) {
      let filtered = [];
      if (selectedTemplate.template_type === 'SMS') {
        filtered = sendingProfiles?.filter((profile) => profile.interface_type === 'SMS Gateway');
      } else if (selectedTemplate.template_type === 'Email' || selectedTemplate.template_type === '') {
        filtered = sendingProfiles?.filter((profile) => profile.interface_type === 'SMTP');
      }
      setSendingProfilesOptions(filtered);
    }
  }, [selectedTemplate, sendingProfiles]);

  async function onSubmit() {
    const data = getValues();
    const sanitizedSendData = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).filter(([_, v]) => v !== undefined),
    );
    const campaignInfo = {
      ...sanitizedSendData,
      url: manualUrlToggle ? manualUrl : buildURL(url, domainOptions),
      // url: `http://localhost:8080${url.path ? `/${url.path}` : ''}`, // Use for local dev
      test_type: selectedTestType,
      learningPage: selectedLearningPage,
      credentialsPage: selectedCredentialsPage,
      template: selectedTemplate,
      difficulty: selectedDifficulty,
      sendingProfile: selectedSendingProfile,
    };
    setSanitizedData(campaignInfo);
    setVisibleConfirmation(true);
  }

  const getFormErrorMessage = (name) => (errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>);

  const itemTemplate = (option) => (
    <div style={{
      display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%',
    }}
    >
      <span>{option?.name}</span>
      <small style={{ color: '#888', marginLeft: 'auto' }}>
        {ownerInfo(option, id)}
      </small>
    </div>
  );

  const groupItemTemplate = (option) => (
    <div>
      <span>{option?.name}</span>
      <small style={{ marginLeft: '20px', color: '#888' }}>
        {option?.num_targets}
        {' '}
        targets
      </small>
      <small style={{
        marginLeft: '2rem', color: '#888', justifyContent: 'flex-end',
      }}
      >
        {ownerInfo(option, id)}
      </small>
    </div>
  );

  const selectedGroupItemTemplate = (option) => (
    selectedGroups && selectedGroups.length > 0 && (
      <div className="p-chip">
        <span className="p-chip-text">
          {option?.name}
          <small>
            {'\u00A0('}
            {option?.num_targets}
            {'\u00A0targets)\u00A0'}
          </small>
        </span>
      </div>
    )
  );

  if (campaign === null && mode === 'edit') {
    return <CustomSpinnerComponent text="campaign" />;
  }

  return (
    <>
      <TestMailModalComponent
        visible={visibleTestMail}
        setVisible={setVisibleTestMail}
        smtp={getValues().sendingProfile}
      />
      {visibleConfirmation && (
        <ConfirmationDialog
          visibleConfirmation={visibleConfirmation}
          setVisibleConfirmation={setVisibleConfirmation}
          sanitizedData={sanitizedData}
          setVisible={setVisible}
          toast={toast}
          navigate={navigate}
          setIsSubmitting={setIsSubmitting}
          isSubmitting={isSubmitting}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2 text-left ml-3">
        <div>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <div className="mb-2">
                <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                  Name*
                </label>
                <InputText
                  id={field.name}
                  placeholder="Campaign name"
                  value={field.value}
                  className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                  onChange={(e) => { field.onChange(e.target.value); trigger('name'); }}
                  aria-describedby={`${field.name}-info`}
                />
                <div className="mb-1">
                  {getFormErrorMessage(field.name)}
                </div>
              </div>
            )}
          />
          <Controller
            name="test_type"
            defaultValue={selectedTestType}
            control={control}
            render={({ field }) => (
              <label htmlFor={field.name} className="text-500 font-medium mb-4">
                Test campaign
                <Checkbox
                  id={field.name}
                  inputId={field.name}
                  checked={field.value}
                  inputRef={field.ref}
                  className="ml-2"
                  onChange={(e) => {
                    field.onChange(e.checked); setSelectedTestType(e.checked);
                  }}
                />
              </label>
            )}
          />
          <Controller
            name="template"
            control={control}
            render={({ field }) => (
              <div className="mb-2">
                <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                  Template*
                </label>
                <Dropdown
                  id={field.name}
                  value={selectedTemplate}
                  onChange={(e) => {
                    setSelectedTemplate(e.value);
                    setValue(field.name, { name: e.value.name });
                    trigger(field.name);
                    resetField('sendingProfile');
                    trigger('sendingProfile');
                  }}
                  options={templatesOptions}
                  optionLabel="name"
                  placeholder={!templatesOptions ? <DropdownLoader /> : 'Select a template'}
                  className="w-full md:w-30rem"
                  disabled={!templatesOptions}
                  itemTemplate={itemTemplate}
                  required
                />
                <div className="mt-1">
                  {getFormErrorMessage(field.name)}
                </div>
              </div>
            )}
          />
          <div className="card flex flex-wrap gap-3 p-fluid">
            <div className="flex-auto">
              <Controller
                name="credentialsPage"
                control={control}
                render={({ field }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      Credentials form page
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="Compromise of Credentials. A landing page that mimics a legitimate webpage with a login form and tracks login attempts on it. If selected, the user will be redirected to the Learning Page on submit."
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center+7"
                        style={{ cursor: 'pointer' }}
                      />
                    </label>
                    <Dropdown
                      id={field.name}
                      value={selectedCredentialsPage}
                      onChange={(e) => {
                        setSelectedCredentialsPage(e.value);
                        setValue(field.name, { name: e.value?.name || undefined });
                        trigger(field.name);
                      }}
                      options={credentialsPagesOptions}
                      optionLabel="name"
                      placeholder={!credentialsPagesOptions ? <DropdownLoader /> : 'Select a landing page'}
                      className="w-full md:w-30rem"
                      disabled={!credentialsPagesOptions}
                      itemTemplate={itemTemplate}
                      showClear
                      required
                    />
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="flex-auto">
              <Controller
                name="learningPage"
                control={control}
                render={({ field }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      Learning page*
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="Teachable Moment. A landing page that features a video for educating users about phishing."
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center+7"
                        style={{ cursor: 'pointer' }}
                      />
                    </label>
                    <Dropdown
                      id={field.name}
                      value={selectedLearningPage}
                      onChange={(e) => {
                        setSelectedLearningPage(e.value);
                        setValue(field.name, { name: e.value.name });
                        trigger(field.name);
                      }}
                      options={learningPagesOptions}
                      optionLabel="name"
                      placeholder={!learningPagesOptions ? <DropdownLoader /> : 'Select a learning page'}
                      className="w-full md:w-30rem"
                      disabled={!learningPagesOptions}
                      itemTemplate={itemTemplate}
                      required
                    />
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
          <Controller
            name="url"
            control={control}
            render={({ field, fieldState }) => (
              <div className="mb-2">
                <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                  URL*
                  <label className="mt-2" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <span className="ml-1">
                      Custom URL&nbsp;
                    </span>
                    <InputSwitch
                      checked={manualUrlToggle}
                      onChange={(e) => {
                        const newToggleValue = e.value;
                        setManualUrlToggle(newToggleValue);
                        if (newToggleValue) {
                          if (manualUrl !== '') {
                            // Avoid YUP validation
                            setValue('url.domain', 'foo');
                          } else {
                            setValue('url.domain', null);
                          }
                        } else {
                          setValue('url', parseUrlString(campaign?.url));
                        }
                        trigger('url');
                      }}
                    />
                  </label>
                </label>
                {!manualUrlToggle ? (
                  <>
                    <div className="flex items-center space-x-4">
                      <Dropdown
                        id={`${field.name}-protocol`}
                        value={url.protocol || 'https'}
                        onChange={(e) => {
                          const updatedValue = { ...field.value, protocol: e.value };
                          setUrl(updatedValue);
                          setValue(field.name, updatedValue);
                          trigger(field.name);
                        }}
                        options={protocols}
                        optionLabel="name"
                        optionValue="name"
                        className="w-full md:w-8rem"
                        required
                      />
                      <InputText
                        id={`${field.name}-subdomain`}
                        placeholder="subdomain"
                        value={field.value?.subdomain}
                        className="w-full md:w-19rem"
                        onChange={(e) => {
                          field.onChange({ ...url, subdomain: e.target.value });
                          setUrl({ ...url, subdomain: e.target.value });
                          trigger(field.name);
                        }}
                        aria-describedby={`${field.name}-subdomain`}
                      />
                      <Dropdown
                        id={`${field.name}-domain`}
                        value={field.value?.domain}
                        onChange={(e) => {
                          const updatedValue = { ...field.value, domain: e.value };
                          setUrl(updatedValue);
                          setValue(field.name, updatedValue);
                          trigger(field.name);
                        }}
                        options={domainOptions}
                        optionLabel="name"
                        optionValue="name"
                        placeholder="Select a domain"
                        className={`w-full md:w-19rem ${fieldState.error ? 'p-invalid' : ''}`}
                        aria-describedby={`${field.name}-domain`}
                        filter
                        showClear
                        required
                      />
                      <InputText
                        id={`${field.name}-path`}
                        placeholder="path"
                        value={field.value?.path}
                        className="w-full md:w-15rem"
                        onChange={(e) => {
                          field.onChange({ ...url, path: e.target.value });
                          setUrl({ ...url, path: e.target.value });
                          trigger(field.name);
                        }}
                        aria-describedby={`${field.name}-path`}
                      />
                    </div>
                    <label className="mt-2">
                      <b>
                        Resulting URL:&nbsp;
                      </b>
                      {getValues('url') && buildURL(getValues('url'), domainOptions)}
                    </label>
                  </>
                ) : (
                  <>
                    <div>
                      <InputText
                        id={field.name}
                        placeholder="URL"
                        value={manualUrl}
                        className={`w-full ${fieldState.error ? 'p-invalid' : ''}`}
                        onChange={(e) => {
                          setManualUrl(e.target.value);
                          setValue('url.domain', e.target.value);
                          trigger('url');
                        }}
                        aria-describedby={`${field.name}-info`}
                      />
                    </div>
                    <label className="mt-2">
                      <b>
                        Resulting URL:&nbsp;
                      </b>
                      {manualUrl && buildURL(manualUrl, domainOptions, true)}
                    </label>
                  </>
                )}
                <div className="mb-1">
                  {getFormErrorMessage(field.name)}
                </div>
              </div>
            )}
          />
          <div className="card flex flex-wrap gap-3 p-fluid">
            <div className="flex-auto">
              <Controller
                name="launch_date"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      Launch date
                    </label>
                    <Calendar
                      id={field.name}
                      value={launchDate}
                      onChange={(e) => { setLaunchDate(e.value); setValue(field.name, e.value.toISOString().replace('Z', '+00:00')); }}
                      showIcon
                      showTime
                      className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                    />
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="flex-auto">
              <Controller
                name="send_by_date"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      End date
                    </label>
                    <Calendar
                      id={field.name}
                      value={endDate}
                      onChange={(e) => { setEndDate(e.value); setValue(field.name, e.value.toISOString().replace('Z', '+00:00')); }}
                      showIcon
                      showTime
                      className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                    />
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
          <Controller
            name="difficulty"
            control={control}
            render={({ field }) => (
              <div className="mb-2">
                <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                  Difficulty*
                </label>
                <Dropdown
                  id={field.name}
                  value={selectedDifficulty}
                  onChange={(e) => {
                    setSelectedDifficulty(e.value);
                    setValue(field.name, { name: e.value });
                    trigger(field.name);
                  }}
                  options={difficultyOptions}
                  optionLabel="name"
                  placeholder="Select the campaign difficulty"
                  className="w-full md:w-30rem"
                  required
                />
                <div className="mb-1">
                  {getFormErrorMessage(field.name)}
                </div>
              </div>
            )}
          />
          <Controller
            name="sendingProfile"
            control={control}
            render={({ field }) => (
              <div className="mb-2">
                <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                  Sending profile*
                </label>
                <Dropdown
                  id={field.name}
                  value={selectedSendingProfile}
                  onChange={(e) => {
                    setSelectedSendingProfile(e.value);
                    setValue(field.name, { name: e.value.name });
                    trigger(field.name);
                  }}
                  options={sendingProfilesOptions}
                  optionLabel="name"
                  placeholder={!sendingProfilesOptions ? <DropdownLoader /> : 'Select a sending profile'}
                  className="w-full md:w-30rem"
                  disabled={!sendingProfilesOptions}
                  itemTemplate={itemTemplate}
                  required
                />
                <Button type="button" disabled={selectedSendingProfile?.interface_type === 'SMS Gateway'} onClick={() => setVisibleTestMail(true)}>Send test mail</Button>
                <div className="mb-1">
                  {getFormErrorMessage(field.name)}
                </div>
              </div>
            )}
          />
          <Controller
            name="groups"
            control={control}
            render={({ field }) => (
              <div className="mb-2">
                <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                  Groups*
                </label>
                <MultiSelect
                  id={field.name}
                  value={selectedGroups}
                  onChange={(e) => {
                    setSelectedGroups(e.value);
                    setValue(field.name, e.value);
                    trigger('groups');
                  }}
                  options={groupsOptions}
                  itemTemplate={groupItemTemplate}
                  optionLabel="name"
                  filter
                  placeholder={!groupsOptions ? <DropdownLoader /> : 'Select groups'}
                  className="w-full"
                  disabled={!groupsOptions}
                  selectedItemTemplate={selectedGroupItemTemplate}
                  required
                />
                <div className="mb-1">
                  {getFormErrorMessage(field.name)}
                </div>
              </div>
            )}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '1rem' }}>
          <Button
            label="Preview"
            icon="pi pi-eye"
            disabled={!isValid || (!manualUrlToggle && checkIfManualUrl(url.domain, domainOptions))}
            type="button"
            onClick={() => onSubmit()}
            autoFocus
          />
        </div>
      </form>
    </>
  );
}

ManageCampaignFormComponent.propTypes = {
  campaign: PropTypes.object,
  sendingProfiles: PropTypes.array,
  setVisible: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
};

ManageCampaignFormComponent.defaultProps = {
  campaign: null,
  sendingProfiles: [],
};

export default ManageCampaignFormComponent;
